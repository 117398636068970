import { Component, HostBinding } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AppState } from '@core/store';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap, take, filter, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import * as oficinasActions from '@core/store/actions/oficinas.actions';
import { currentOffices, disabledOffices, loadedOffices, oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { SciOficina, SciUsuarioOficinaPerfil } from '~models/config';

@Component({
	selector: 'select-office',
	styleUrls: ['./select-office.component.scss'],
	template: `
		<ng-container *ngIf="disabledOffice$ | async; else tempButton">
			<div class="d-flex">
				<span class="text-white">{{ sciOficina?.nombre ?? 'SELECCIONE OFICINA' }}</span>
			</div>
		</ng-container>

		<ng-template #tempButton>
			<button (click)="selectOffice.open()" class="d-flex align-items-center">
				<span>{{ sciOficina?.nombre ?? 'SELECCIONE OFICINA' }}</span>
				<mat-icon class="d-flex align-items-center">expand_more</mat-icon>
			</button>
		</ng-template>

		<mat-select panelClass="true" #selectOffice class="invisible w-0 h-0" [formControl]="formControl">
			<mat-option *ngFor="let oficina of oficinas$ | async" (click)="selectOficina($any($event), oficina.sciOficina)" [value]="oficina.sciOficina">{{ oficina.sciOficina.nombre }}</mat-option>
		</mat-select>
	`,
})
export class SelectOfficeComponent {
	@HostBinding('class') class = 'd-flex align-items-center';

	oficinas$: Observable<SciUsuarioOficinaPerfil[]> = of([]);

	formControl = new UntypedFormControl('');

	get sciOficina(): SciOficina | null {
		const { value } = this.formControl;
		if (value == null) return null;

		return value as SciOficina;
	}

	disabledOffice$: any;

	constructor(private store: Store<AppState>) {
		this.oficinas$ = this.store.select(loadedOffices).pipe(
			filter((loaded) => loaded),
			withLatestFrom(this.store.pipe(select(currentOffices))),
			map(([_, usuarioOficinasPerfiles]) => usuarioOficinasPerfiles)
		);

		this.store
			.select(disabledOffices)
			.pipe(
				tap((disabledOffices) => {
					if (disabledOffices) {
						this.formControl.disable({ emitEvent: false });
						return;
					}

					this.formControl.enable({ emitEvent: false });
				})
			)
			.subscribe();

		this.store
			.select(oficinaSelected)
			.pipe(
				filter((res) => res !== null),
				take(1),
				tap((oficina) => {
					if (oficina) {
						this.formControl.patchValue(oficina);
					}
				})
			)
			.subscribe();

		this.formControl.valueChanges.pipe(distinctUntilChanged()).subscribe((oficina) => {
			if (oficina == null) return;

			this.store.dispatch(
				oficinasActions.cambiarOficinaSeleccionada({
					sciOficina: oficina,
				})
			);
		});
		this.disabledOffice$ = this.store.select(disabledOffices);
	}

	selectOficina(event: PointerEvent, sciOficina: SciOficina): void {
		if (event.ctrlKey) {
			window.open(`#/oficina/${sciOficina.idOficina}`);
		}
	}
}
