import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { SciMenuListado } from '@core/store/models/SciMenuListado';
import { SciUsuarioOficinaPerfil } from '~models/config';
import { RefreshTokenDto } from '~shared/interface/RefreshTokenDto';
import { RespuestaApi } from '~shared/interface/RespuestaApi';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { CookiesService } from '@utils/services/cookies.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly _COD_SISTEMA = environment.COD_SISTEMA;
	private readonly _HOST_SECURITY = `${environment.HOST_SECURITY}`;
	private readonly _HOST_CONFIG_USUARIO_OFICINA = `${environment.HOST_CONFIG}/sciusuariooficinaperfiles`;
	private readonly _HOST_MAESTROS_SYNC_MENU = `${environment.HOST_MAESTROS_SYNC}/scimenus`;
	private readonly _HOST_CONFIG_ACCESOS = `${environment.HOST_CONFIG}/sciaccesos`;

	constructor(private readonly _http: HttpClient, private readonly _cookiesService: CookiesService) {}

	refreshToken() {
		const refreshToken = this._cookiesService.getItem(environment.refreshToken);
		const refreshTokenDto: RefreshTokenDto = { refreshToken };
		return this._http.post<RespuestaApi>(`${this._HOST_SECURITY}/refresh-token`, refreshTokenDto);
	}

	updateTokens(idToken: string, accessToken: string, maestroSyncToken: string): void {
		this._cookiesService.setItem(environment.idToken, idToken);
		this._cookiesService.setItem(environment.authTokenKey, accessToken);
		this._cookiesService.setItem(environment.maestroSyncToken, maestroSyncToken);
	}

	logout(token: string): Observable<RespuestaApi | null> {
		if (!token) {
			return of(null);
		}
		return this._http.post<RespuestaApi>(`${this._HOST_SECURITY}/signout`, { token }).pipe(
			map((respuesta) => {
				return respuesta;
			})
		);
	}

	authorize(codigo: string) {
		const body = { codigo };
		return this._http.post<ApiResponse>(`${this._HOST_SECURITY}/authorize`, body).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	/* Listar los accesos */
	findAllByCodigoSistemaAndIdOficina(idOficina: number) {
		return this._http
			.get<ApiResponse<SciFormularioAccionListado[]>>(`${this._HOST_CONFIG_ACCESOS}/model-se-ro/findAllByCodigoSistemaAndIdOficina/${this._COD_SISTEMA}/${idOficina}`)
			.pipe(map((res) => res.data));
	}

	/* Listas las oficinas a las que el usuario tiene acceso */
	findAllSciOficinaByIdUsuario(idUsuario: number) {
		return this._http.get<ApiResponse<SciUsuarioOficinaPerfil[]>>(`${this._HOST_CONFIG_USUARIO_OFICINA}/model-se-ro/findAllSciOficinaByIdUsuario/${idUsuario}`).pipe(map((res) => res.data));
	}

	/* Lista de menu */
	findAllByCodigoSistemaAndIdFormularioList(idFormularioList: number[]) {
		return this._http
			.post<ApiResponse<SciMenuListado[]>>(`${this._HOST_MAESTROS_SYNC_MENU}/findAllByCodigoSistemaAndIdFormularioList/${this._COD_SISTEMA}`, idFormularioList)
			.pipe(map((res) => res.data));
	}
}
