// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { BaseComponent } from '@theme/base/base.component';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { ControlModule } from 'app/modules/control/control.module';
import { environment } from 'environments/environment';

// Components

const routes: Routes = [
	{
		path: environment.PATH_BASE,
		component: BaseComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			// {
			// 	path: 'dashboard',
			// 	data: { titulo: 'Dashboard' },
			// 	loadChildren: (): Promise<typeof DashboardModule> => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			// },
			{
				path: 'control',
				loadChildren: (): Promise<typeof ControlModule> => import('app/modules/control/control.module').then((m) => m.ControlModule),
			},
			// { path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: `/${environment.PATH_BASE}/control/credenciales`, pathMatch: 'full' },
			{ path: '**', redirectTo: '../error/403', pathMatch: 'full' },
		],
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo ratón, aún no desarrollamos esto.<br> Please, contact Almircar.',
		},
	},
	{
		path: 'error/acceso-denegado',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'No tiene permisos para ver esta página',
		},
	},
	{ path: '**', redirectTo: environment.PATH_BASE, pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
