// Angular
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@core/store';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { Store } from '@ngrx/store';
import { CookiesService } from '@utils/services/cookies.service';
// RxJS
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(private cookiesService: CookiesService, private store: Store<AppState>) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let clonedRequest: any;
		if (req.url.indexOf('sumax-erp-backend-maestros-sync') !== -1) {
			clonedRequest = this.cookiesService.getBearerAuthorization('maestroSyncToken');
			let headers = new HttpHeaders({ Authorization: clonedRequest });
			return next.handle(req.clone({ headers }));
		} else {
			clonedRequest = this.cookiesService.getBearerAuthorization('idToken');
			return this.store.select(oficinaSelected).pipe(
				first(),
				mergeMap((res) => {
					let headerObject: { [key: string]: string } = { Authorization: clonedRequest };
					if (res?.idOficina) {
						headerObject = { Authorization: clonedRequest, idOficina: res.idOficina.toString() };
					}
					let headers = new HttpHeaders(headerObject);
					return next.handle(req.clone({ headers }));
				})
			);
		}
	}
}

export const authInterceptorProviders = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptorService,
	multi: true,
};
